<template>
  <div class='user'>
    <div class='globle_border' v-loading="loading">
      <div class="search">
        <el-input v-model="search" placeholder="搜索专家名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">添加</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="userName" label="专家姓名" width="180"></el-table-column>
          <el-table-column prop="departmentName" label="联系方式" width="200"></el-table-column>
          <el-table-column prop="identityCard" label="身份证号" width="200"></el-table-column>
          <el-table-column prop="departmentName" label="账号" width="200"></el-table-column>
          <el-table-column prop="departmentName" label="密码" width="200"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button class="btn" type="" size="" plain @click="handleEdit(scope.$index, scope.row)">修改</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDelete(scope.$index, scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <el-dialog :title="isAdd ? '添加' : '修改'" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="专家姓名" prop="userName">
              <el-input v-model="ruleForm.userName" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="账号" prop="userAccount">
              <el-input v-model="ruleForm.userAccount" placeholder="请输入登录账号"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="userPassword">
              <el-input v-model="ruleForm.userPassword" placeholder="请输入登录密码" show-password></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号" prop="identityCard">
              <el-input v-model="ruleForm.identityCard" placeholder="请输入身份证号"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="userPhone">
              <el-input v-model="ruleForm.userPhone" placeholder="请输入联系电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
function createInitRuleForm () {
  return {
    userName: '',
    userSex: '',
    birthDate: '',
    departmentId: '',
    identityCard: '',
    userPhone: '',
    nativePlace: '',
    userAccount: '',
    userPassword: '',
    roleIds: [],
  }
}
export default {
  data () {
    // 正则表达式验证身份证的格式  
    var validateIdCard = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('身份证不能为空'));
      }
      const idCardRegex = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}([0-9]|X)$/;
      if (!idCardRegex.test(value)) {
        callback(new Error('身份证格式不正确'));
      } else {
        callback();
      }
    };
    var checkTel = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('联系电话不能为空'));
      }
      const telRegex = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (!telRegex.test(value)) {
        callback(new Error('联系电话格式不正确'));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      submitLoding: false,
      search: '',
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      dialogVisible: false,
      isAdd: true,
      ruleForm: createInitRuleForm(),
      rules: {
        userName: [{ required: true, message: '请输入姓名', trigger: 'submit' }],
        userSex: [{ required: true, message: '请选择性别', trigger: 'submit' }],
        birthDate: [{ required: true, message: '请选择日期', trigger: 'submit' }],
        departmentId: [{ required: true, message: '请选择部门', trigger: 'submit' }],
        roleIds: [{ required: true, message: '请选择角色', trigger: 'submit' }],
        nativePlace: [{ required: true, message: '请输入籍贯', trigger: 'submit' }],
        userAccount: [{ required: true, message: '请输入登录账号', trigger: 'submit' }],
        userPhone: [{ required: true, validator: checkTel, trigger: 'submit' }],
        identityCard: [{ required: true, validator: validateIdCard, trigger: 'submit' }],
        userPassword: [{ required: true, message: '请输入登录密码', trigger: 'submit' }],
      },
      departmentList: [],
      roleList: [],
    }
  },
  created () {
    this.getSysUserList()
    // this.getSysDepartmentList()
    // this.getSysRoleList()
  },
  methods: {
    // 列表
    getSysUserList () {
      this.loading = true
      this.$axios.get(this.$api.getSysUserList, {
        params: {
          userName: this.search,
          page: this.currentPage,
          pageSize: this.pageSize
        }
      }).then((res) => {
        this.tableData = res.data.result.list
        this.totalItemsCount = res.data.result.totalCount
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    // 角色列表
    getSysRoleList () {
      this.$axios.get(this.$api.getSysRoleList, {
        params: {
          page: 1,
          pageSize: 999,
        }
      }).then((res) => {
        let list = res.data.result.list.filter(e => e.status == '1')
        this.roleList = list
      }).catch(() => {
        // 异常处理
      })
    },
    // 搜索
    onSearch () {
      this.getSysUserList()
    },
    // 新增（对话框）
    handleAdd () {
      this.isAdd = true
      this.dialogVisible = true
    },
    // 修改（对话框）
    handleEdit (index, row) {
      this.isAdd = false;
      this.$axios.get(`${this.$api.getSysUserById}/${row.userId}`).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result;
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      }).catch(() => {
        this.dialogVisible = false;
      });
    },
    // 删除
    handleDelete (index, row) {
      this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.put(`${this.$api.deleteSysUser}/${row.userId}`)
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getSysUserList()
          }).catch(() => { })
      }).catch(() => { });
    },
    // 切换每页显示的条数
    handleSizeChange (e) {
      this.pageSize = e
      this.getSysUserList()
    },
    // 换页
    handleCurrentChange (e) {
      this.currentPage = e
      this.getSysUserList()
    },
    // 关闭对话框
    onCancel () {
      this.dialogVisible = false
      this.resetForm()
    },
    // // 选择部门
    // changeCascader (arr) {
    //   // 截取arr最后一个元素
    //   this.ruleForm.departmentId = arr[arr.length - 1]
    // },
    // 提交表单
    submitForm (formName) {
      this.submitLoding = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.isAdd) {
            this.$axios.post(this.$api.addSysUser, {
              userName: this.ruleForm.userName,
              userSex: this.ruleForm.userSex,
              birthDate: this.ruleForm.birthDate,
              userPhone: this.ruleForm.userPhone,
              departmentId: this.ruleForm.departmentId,
              identityCard: this.ruleForm.identityCard,
              nativePlace: this.ruleForm.nativePlace,
              userAccount: this.ruleForm.userAccount,
              userPassword: this.ruleForm.userPassword,
              roleIds: this.ruleForm.roleIds,
            }).then((res) => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc);
              }
              this.getSysUserList();
              this.submitLoding = false
              this.dialogVisible = false;
              this.resetForm();
            }).catch((err) => {
              this.$message.error(err.data.desc);
            });
          } else {
            this.$axios.put(this.$api.updateSysUser, {
              userId: this.ruleForm.userId,
              userName: this.ruleForm.userName,
              userSex: this.ruleForm.userSex,
              birthDate: this.ruleForm.birthDate,
              userPhone: this.ruleForm.userPhone,
              departmentId: this.ruleForm.departmentId,
              identityCard: this.ruleForm.identityCard,
              nativePlace: this.ruleForm.nativePlace,
              userAccount: this.ruleForm.userAccount,
              userPassword: this.ruleForm.userPassword,
              roleIds: this.ruleForm.roleIds,
            }).then((res) => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc);
              }
              this.getSysUserList();
              this.submitLoding = false
              this.dialogVisible = false;
              this.resetForm();
            }).catch((err) => {
              this.submitLoding = false
              this.$message.error(err.data.desc);
            });
          }
        } else {
          this.submitLoding = false
          this.$message.error('提交失败');
          return false;
        }
      });
    },
    // 重置校验
    resetForm () {
      this.ruleForm = createInitRuleForm()
      this.$refs.ruleForm.resetFields();
    },
  },
}
</script>

<style scoped lang='scss'>
.user {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 300px;

      ::v-deep .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-button {
      margin-left: 20px;
    }
  }

}
</style>
